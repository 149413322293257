<template>
  <div>
    <v-alert v-if="success" border="bottom" color="success" dark>
      {{ successMensaje }}
    </v-alert>
    <v-alert v-if="error" border="bottom" color="red" dark>
      <b>Ocurrió un error al guardar el Parametro.</b> {{ errorMensaje }}
    </v-alert>
    <v-form v-model="valid" ref="form">
      <v-row>
        <v-col cols="4" md="4">
          <v-autocomplete
            v-model="servicios"
            :items="tipoServicios"
            required
            filled
            label="Tipo de Operatorias"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4" md="4">
          <v-text-field
            v-model="parametros"
            :rules="nameRules"
            label="Parametro"
            required
            filled
          ></v-text-field>
        </v-col>
        <v-col cols="4" md="4">
          <v-text-field
            v-model="descripcion"
            label="Descripcion"
            required
            filled
          ></v-text-field>
        </v-col>
        <v-col cols="4" md="4">
          <v-autocomplete
            v-model="destino"
            :items="[
              { value: 'cliente', text: 'Cliente' },
              { value: 'operatoria', text: 'Operatoria' },
            ]"
            required
            filled
            label="Destino Parametro"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4" md="4">
          <v-autocomplete
            v-model="options"
            :items="[
              { value: 'int', text: 'Unidades' },
              { value: 'float', text: 'Pesos/Dolares' },
              { value: 'percent', text: 'Porcentaje' },
              { value: 'string', text: 'Texto' },
              { value: 'list', text: 'Lista' },
              { value: 'month', text: 'Mes' },
            ]"
            required
            filled
            label="Información Requerida"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4" md="4">
          <v-text-field
            v-if="!editar"
            v-model="clave"
            label="Clave"
            required
            filled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="options === 'list'">
        <v-col>
          <v-autocomplete
            v-model="deleteItem"
            :items="list"
            label="Lista de opciones"
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-text-field
            cols="1"
            v-model="textList"
            label="Descripción"
            required
            filled
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="valueText"
            label="ID"
            required
            filled
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn x-large color="white--text blue lighten-1" @click="addList">
            Agregar
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="2">
          <v-btn
            v-if="!editar"
            color="primary"
            x-large
            :loading="loading"
            @click="guardar"
          >
            Guardar
          </v-btn>
          <v-btn
            v-if="editar"
            color="warning"
            x-large
            :loading="loading"
            @click="modificar"
          >
            Editar
          </v-btn>
        </v-col>
        <v-col md="2">
          <v-btn color="success" x-large @click="listado">Ver Listado</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';

import axios from 'axios';

export default {
  data: () => ({
    loading: false,
    success: false,
    error: false,
    successMensaje: '',
    errorMensaje: '',
    servicios: [],
    descripcion: '',
    clave: '',
    options: null,
    list: [],
    destino: null,
    tipoServicios: [],
    tipoParametros: '',
    parametros: '',
    deleteItem: '',
    valid: false,
    email: '',
    items: [],
    password: '',
    textList: '',
    valueText: '',
    nameRules: [(v) => !!v || 'El campo nombre es obligatorio'],
    emailRules: [
      (v) => !!v || 'El correo electronico es obligatorio',
      (v) => /.+@.+/.test(v) || 'El correo electronico no es valido',
    ],
  }),
  props: ['editar'],
  methods: {
    addList() {
      if (this.textList !== '' && this.valueText !== '') {
        this.$swal.fire({
          icon: 'success',
          title: 'Agregado con exito',
          showConfirmButton: false,
          timer: 1500,
        });
        this.list.push({ text: this.textList, value: this.valueText });
        this.textList = '';
        this.valueText = '';
      } else {
        this.$swal.fire({
          icon: 'error',
          title: 'Los campos deben estar llenos!!',
          showConfirmButton: false,
          timer: 1000,
        });
      }
    },
    eliminarItemOption() {
      this.list = this.list.filter((el) => el.value !== this.deleteItem);
      this.deleteItem = '';
    },
    resetForm() {
      this.$refs.form.reset();
    },
    listado() {
      this.$router.push(`/listado/${this.$route.params.model}/`);
    },
    async guardar() {
      this.loading = true;
      const data = {
        servicio_id: this.servicios,
        nombre: this.parametros,
        descripcion: this.descripcion,
        clave: this.clave,
        options:
          this.options === 'list' ? JSON.stringify(this.list) : this.options,
        destino: this.destino,
      };
      const url = `${API_URL}api/tipoParametros`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      await axios.post(url, data, header).then((response) => {
        if (response.data.status === 'success') {
          this.loading = false;
          this.success = true;
          this.successMensaje = response.data.message;
          this.resetForm();
        } else if (response.data.status === 'error') {
          this.error = true;
          this.errorMensaje = response.data.message;
          this.loading = false;
        }
      });
    },
    async modificar() {
      this.loading = true;
      this.error = false;
      this.success = false;
      axios
        .post(
          `${API_URL}api/tipoParametros/editar/`,
          {
            parameter_id: this.$route.params.id,
            servicio_id: this.servicios,
            nombre: this.parametros,
            descripcion: this.descripcion,
            destino: this.destino,
            options:
              this.options === 'list'
                ? JSON.stringify(this.list)
                : this.options,
          },
          {
            headers: {
              token: localStorage.getItem('token'),
            },
          }
        )
        .then((response) => {
          this.loading = false;
          if (response.data.status === 'success') {
            this.loading = false;
            this.success = true;
            this.successMensaje = response.data.message;
          } else if (response.data.status === 'error') {
            this.error = true;
            this.errorMensaje = response.data.message;
            this.loading = false;
          }
        });
    },
    presentarDatos() {
      axios
        .get(`${API_URL}api/tipoParametros/${this.$route.params.id}`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          this.parametros = response.data.tipoParametros.nombre;
          this.servicios = response.data.tipoServicios.id;
          this.descripcion = response.data.tipoParametros.descripcion;
          this.clave = response.data.tipoParametros.clave;
          this.destino = response.data.tipoParametros.destino;
          this.options = response.data.tipoParametros.options;
        });
    },
    presentarServicios() {
      axios
        .get(`${API_URL}api/tipoServicios`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.tipoServicios = response.data.tipoServicios.map((f) => ({
              value: f.id,
              text: f.nombre,
            }));
          } else {
            console.log('Error al obtener los datos');
          }
        });
    },
  },
  created() {
    if (this.editar) {
      this.presentarServicios();
      this.presentarDatos();
    } else {
      this.presentarServicios();
    }
  },
};
</script>
