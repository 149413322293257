var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.success)?_c('v-alert',{attrs:{"border":"bottom","color":"success","dark":""}},[_vm._v(" "+_vm._s(_vm.successMensaje)+" ")]):_vm._e(),(_vm.error)?_c('v-alert',{attrs:{"border":"bottom","color":"red","dark":""}},[_c('b',[_vm._v("Ocurrió un error al guardar el Parametro.")]),_vm._v(" "+_vm._s(_vm.errorMensaje)+" ")]):_vm._e(),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.tipoServicios,"required":"","filled":"","label":"Tipo de Operatorias"},model:{value:(_vm.servicios),callback:function ($$v) {_vm.servicios=$$v},expression:"servicios"}})],1),_c('v-col',{attrs:{"cols":"4","md":"4"}},[_c('v-text-field',{attrs:{"rules":_vm.nameRules,"label":"Parametro","required":"","filled":""},model:{value:(_vm.parametros),callback:function ($$v) {_vm.parametros=$$v},expression:"parametros"}})],1),_c('v-col',{attrs:{"cols":"4","md":"4"}},[_c('v-text-field',{attrs:{"label":"Descripcion","required":"","filled":""},model:{value:(_vm.descripcion),callback:function ($$v) {_vm.descripcion=$$v},expression:"descripcion"}})],1),_c('v-col',{attrs:{"cols":"4","md":"4"}},[_c('v-autocomplete',{attrs:{"items":[
            { value: 'cliente', text: 'Cliente' },
            { value: 'operatoria', text: 'Operatoria' } ],"required":"","filled":"","label":"Destino Parametro"},model:{value:(_vm.destino),callback:function ($$v) {_vm.destino=$$v},expression:"destino"}})],1),_c('v-col',{attrs:{"cols":"4","md":"4"}},[_c('v-autocomplete',{attrs:{"items":[
            { value: 'int', text: 'Unidades' },
            { value: 'float', text: 'Pesos/Dolares' },
            { value: 'percent', text: 'Porcentaje' },
            { value: 'string', text: 'Texto' },
            { value: 'list', text: 'Lista' },
            { value: 'month', text: 'Mes' } ],"required":"","filled":"","label":"Información Requerida"},model:{value:(_vm.options),callback:function ($$v) {_vm.options=$$v},expression:"options"}})],1),_c('v-col',{attrs:{"cols":"4","md":"4"}},[(!_vm.editar)?_c('v-text-field',{attrs:{"label":"Clave","required":"","filled":""},model:{value:(_vm.clave),callback:function ($$v) {_vm.clave=$$v},expression:"clave"}}):_vm._e()],1)],1),(_vm.options === 'list')?_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.list,"label":"Lista de opciones"},model:{value:(_vm.deleteItem),callback:function ($$v) {_vm.deleteItem=$$v},expression:"deleteItem"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"cols":"1","label":"Descripción","required":"","filled":""},model:{value:(_vm.textList),callback:function ($$v) {_vm.textList=$$v},expression:"textList"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"ID","required":"","filled":""},model:{value:(_vm.valueText),callback:function ($$v) {_vm.valueText=$$v},expression:"valueText"}})],1),_c('v-col',[_c('v-btn',{attrs:{"x-large":"","color":"white--text blue lighten-1"},on:{"click":_vm.addList}},[_vm._v(" Agregar ")])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[(!_vm.editar)?_c('v-btn',{attrs:{"color":"primary","x-large":"","loading":_vm.loading},on:{"click":_vm.guardar}},[_vm._v(" Guardar ")]):_vm._e(),(_vm.editar)?_c('v-btn',{attrs:{"color":"warning","x-large":"","loading":_vm.loading},on:{"click":_vm.modificar}},[_vm._v(" Editar ")]):_vm._e()],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-btn',{attrs:{"color":"success","x-large":""},on:{"click":_vm.listado}},[_vm._v("Ver Listado")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }